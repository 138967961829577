<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">订单详情</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">

					<div class="">
						<div class="flex alcenter">
							<div class="mall-goods_order-detail-tag ml20" style="background: #4772FF;"
								v-if="order.logistics_type==1">快递单</div>
							<div class="mall-goods_order-detail-tag ml20" style="background: #00BA26;"
								v-if="order.logistics_type==2">自提单</div>
							<div class="ml8">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.order_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">订单类型：</div>
										<div class="ft14 ftw600 cl-theme text-over4">{{order.order_type_means}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="mall-goods_order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==1">待付款</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==2">待发货</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==3">已发货</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==4">待到店自提</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==8">已完成</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==-1">已取消</div>
									<div class="button-deliver mt16" @click="deliverGoodsAct()" v-if="order.logistics_type==1 && order.status==2">
										确认发货</div>
									<div class="button-deliver mt16" @click="overGoodsAct()" v-if="order.logistics_type==1 && order.status==3">
										完成订单</div>	
									<div class="button-deliver mt16" @click="writeOffAct()" v-if=" order.status==4">确认核销</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter">
															<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
															<div class="ft14 ftw600 cl-main text-over4">
																¥{{order.need_pay}}</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
															<div class="ft14 ftw600 cl-main text-over4" v-if="order.status==1 || order.status ==-1">未支付</div>
															<div class="ft14 ftw600 cl-main text-over4" v-else>
																{{order.pay_type_mean}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_time_format}}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="ml40">
										<div class="ft14 ftw500 cl-main">客户信息</div>
										<div class="mt16">
											<div class="flex">
												<div>
													<div class="flex alcenter">
														<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_name}}</div>
													</div>
													<div class="flex alcenter mt10">
														<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_mobile}}</div>
													</div>
													<div class="flex  mt10" v-if="order.logistics_type==1">
														<div class="ft14 ftw400 cl-info text-over4">收货地址：</div>
														<div>
															<div class="ft14 ftw600 cl-main text-over4">
																{{order.receiving_name}} {{order.receiving_mobile}}
															</div>
															<div class="ft14 ftw600 cl-main text-over4">
																{{order.receiving_address_province}}{{order.receiving_address_city}}{{order.receiving_address_county}}{{order.receiving_address_info}}
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="flex wrap">
							<div class="mall-goods_order-detail-subcard-item" v-for="(item,index) in order.items">
								<div class="flex  alcenter space">
									<div class="flex alcenter">
										<img v-if="item.goods_cover_img != null" :src="item.goods_cover_img" />
										<div class="ml10">
											<div class="ft14 ftw500 cl-info" style="width: 60%;">
												<span class="mall-goods_order-detail tag"
													v-if="item.goods_type=='pre_sale'">
													付款{{item.yu_days}}日后发货
												</span>
												<span class="mall-goods_order-detail tag"
													v-if="item.goods_type=='pin_tuan'">
													{{item.group_size}}人团
												</span>
												{{item.goods_name}}
											</div>
											<div class="ft12 ftw400 cl-notice">{{item.goods_sku_name}}</div>
										</div>
										<div style="margin-left: 200px;" class="ft14 ftw500 cl-info flex alcenter">
											<div>¥{{item.goods_price}}</div>
											<div class="ml8">x{{item.goods_num}}</div>
										</div>
									</div>

									<div v-if="order.items.length==1 && order.status==2 && order.logistics_type == 1">
										<div class="button-deliver"  @click="deliverGoodsAct()">确认发货</div>
									</div>
									<div v-if="order.items.length==1 && order.status==3 && order.logistics_type == 1">
										<div class="button-deliver"  @click="overGoodsAct()">完成订单</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex end mt10">
						<div class="flex">
							<div class="ft12 ftw400 cl-info">
								<div class="flex end">合计</div>
								<div class="flex end mt8" v-if="order.logistics_type==1">运费</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">积分抵扣</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">优惠金额</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
							</div>

							<div class="ml60 ft12 ftw400 cl-info">
								<div class="flex end">¥{{order.total_price}}</div>
								<div class="flex end mt8" v-if="order.logistics_type==1">¥{{order.freight}}</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">¥{{order.coupon_money}}</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">¥{{order.integral_balance}}
								</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">¥{{order.youhui_balance}}</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">¥{{order.need_pay}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				order_id: 0,
				order: {
					order_id: 0,
					logistics_type: 1,
					add_time_format: '',
					order_type_means: '',
					status: 0,
					status_means: '',
					need_pay: 0,
					pay_type_mean: '',
					pay_time_format: '',
					receiving_name: '',
					receiving_mobile: '',
					receiving_address_province: '',
					receiving_address_city: '',
					receiving_address_county: '',
					receiving_address_info: '',
					total_price: 0,
					freight: 0,
					coupon_money: 0,
					integral_balance: 0,
					member_name: '',
					member_mobile: '',
					items: []
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.order_id = 0;
			} else {
				this.order_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getGoodsOrderDetail', {
					order_id: this.order_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},
			overGoodsAct(){
				this.$confirm({
					title:'确认完成这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/overGoodsOrder',{
								order_id:this.order_id,
							}).then(res=>{
								this.$message.success('完成成功');
								this.order.status=8;
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			deliverGoodsAct(){
				this.$confirm({
					title:'确认发货这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/deliverGoodsOrder',{
								order_id:this.order_id,
							}).then(res=>{
								this.$message.success('发货成功');
								this.order.status=3;
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			writeOffAct(){
				this.$confirm({
					title:'确定核销这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/writeOffOrder',{
								order_id:this.order_id,
							}).then(res=>{
								this.$message.success('核销成功');
								this.order.status=8;
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
		}
	}
</script>

<style>
	.mall-goods_order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.mall-goods_order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.mall-goods_order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.mall-goods_order-detail-subcard-item {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.mall-goods_order-detail-subcard-item img {
		width: 75px;
		height: 60px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
</style>
